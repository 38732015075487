import {Injectable} from '@angular/core';
import {ApiHttpService} from '@Core/services/api-http/api-http.service';
import {Page} from '@Shared/interfaces/page';
import {map, Observable} from 'rxjs';
import {Permission} from '@Shared/component-library/interfaces/permission';
import {CentreSummary} from '@Pages/user-management/interfaces/centre-summary';
import {CreateUserRequest} from '@Pages/user-management/interfaces/create-user-request';
import {RoleWithPermissions} from '@Pages/user-management/interfaces/role-with-permissions';
import {UserStatus} from '@Pages/user-management/interfaces/user-status';
import {UpdateUserStatusRequest} from '@Pages/user-management/interfaces/update-user-status-request';
import {UserStatusIds} from '@Pages/user-management/enums/user-status';
import {UserDetails} from '@Pages/user-management/interfaces/user-details';
import {EditUserRequest} from '@Pages/user-management/interfaces/edit-user-request';
import {AddUserImage} from '@Shared/interfaces/api-requests/add-user-image';
import {UserProfileResponse} from '@Shared/interfaces/api-responses/user-profile-response';
import {ApiEndpointsProfileService} from '../api-endpoints-profile/api-endpoints-profile.service';
import {UserImage} from '@Shared/interfaces/api-responses/user-image';
import {SaveMyProfile} from '@Shared/interfaces/api-requests/save-my-profile';
import {ProductFeature} from '@Shared/interfaces/product-feature';
import {ProfileImageType} from '@Shared/enums/profile-image-type';
import {UserProfile} from '@Shared/classes/user-profile/user-profile';
import {environment} from '@Env/environment';
import {currentUser} from '../user-context/user-context.service';
import {userTypes} from '@Shared/constants/user-types';
import {ActivateUserRequest} from '@Shared/interfaces/api-requests/activate-user-request';

@Injectable({
  providedIn: 'root',
})
export class ProfileApiService {
  constructor(
    private readonly apiHttpService: ApiHttpService,
    private readonly apiEndpointsProfileService: ApiEndpointsProfileService
  ) {}

  getCurrentUser(): Observable<UserDetails> {
    return this.apiHttpService.get<UserDetails>(this.apiEndpointsProfileService.getCurrentUser(), false);
  }

  getUser(accountId: string): Observable<UserDetails> {
    return this.apiHttpService.get<UserDetails>(this.apiEndpointsProfileService.getUser(accountId), false);
  }

  getVendorUserByEmailAddress(email: string): Observable<UserDetails> {
    return this.apiHttpService.get<UserDetails>(
      this.apiEndpointsProfileService.getVendorUserByEmailAddress(email),
      false
    );
  }

  getMyProfile(): Observable<UserProfile> {
    return this.apiHttpService
      .get<UserProfileResponse>(this.apiEndpointsProfileService.getMyProfile(), false)
      .pipe(map((response) => ProfileApiService.toUserProfile(response)));
  }

  getUserProfile(accountId: string): Observable<UserProfile> {
    return this.apiHttpService
      .get<UserProfileResponse>(this.apiEndpointsProfileService.getUserProfile(accountId), false)
      .pipe(map((response) => ProfileApiService.toUserProfile(response)));
  }

  createUser(createUserRequest: CreateUserRequest): Observable<string> {
    return this.apiHttpService.post<string>(this.apiEndpointsProfileService.createUser(), createUserRequest);
  }

  createVendorUser(createUserRequest: CreateUserRequest): Observable<string> {
    return this.apiHttpService.post<string>(this.apiEndpointsProfileService.createVendorUser(), createUserRequest);
  }

  editUser(accountId: string, editUserRequest: EditUserRequest): Observable<null> {
    return this.apiHttpService.put<null>(this.apiEndpointsProfileService.editUser(accountId), editUserRequest);
  }

  activateUser(accountId: string): Observable<null> {
    const request: UpdateUserStatusRequest = {
      userStatusId: UserStatusIds.active,
    };
    return this.apiHttpService.put(this.apiEndpointsProfileService.updateUserStatus(accountId), request);
  }

  deactivateUser(accountId: string): Observable<null> {
    const request: UpdateUserStatusRequest = {
      userStatusId: UserStatusIds.inactive,
    };
    return this.apiHttpService.put(this.apiEndpointsProfileService.updateUserStatus(accountId), request);
  }

  getCentres(tenantId: string, index: number, size: number): Observable<Page<CentreSummary>> {
    return this.apiHttpService.get<Page<CentreSummary>>(
      this.apiEndpointsProfileService.getCentres(tenantId, index, size),
      false
    );
  }

  getUserStatuses(): Observable<UserStatus[]> {
    return this.apiHttpService.get<UserStatus[]>(this.apiEndpointsProfileService.getUserStatuses(), false);
  }

  getPermissions(productId: string): Observable<Permission[]> {
    return this.apiHttpService.get<Permission[]>(
      this.apiEndpointsProfileService.getProductEnabledPermissions(productId),
      false
    );
  }

  getRole(roleId: string): Observable<RoleWithPermissions> {
    return this.apiHttpService.get<RoleWithPermissions>(this.apiEndpointsProfileService.getRole(roleId), false);
  }

  deleteRole(roleId: string): Observable<null> {
    return this.apiHttpService.delete(this.apiEndpointsProfileService.deleteRole(roleId), false);
  }

  createRole(
    name: string,
    description: string,
    permissionIds: string[],
    productId: string,
    tenantId: string
  ): Observable<null> {
    return this.apiHttpService.post<null>(this.apiEndpointsProfileService.createRole(), {
      productId,
      tenantId,
      roleName: name,
      roleDescription: description,
      permissions: permissionIds,
    });
  }

  editRole(
    id: string,
    name: string,
    description: string,
    permissionIds: string[],
    productId: string,
    tenantId: string
  ): Observable<null> {
    return this.apiHttpService.put(this.apiEndpointsProfileService.editRole(id), {
      productId,
      tenantId,
      roleId: id,
      roleName: name,
      roleDescription: description,
      permissions: permissionIds,
    });
  }

  uploadProfileImage(imageDetails: AddUserImage): Observable<UserImage> {
    const uri = this.apiEndpointsProfileService.addProfileImage();
    const formData = new FormData();
    formData.append('userImageType', imageDetails.imageType);
    if (imageDetails.originalImage) {
      formData.append('originalImage', imageDetails.originalImage, imageDetails.originalImage.name);
    }
    if (imageDetails.thumbnailImage) {
      formData.append('thumbnailImage', imageDetails.thumbnailImage, imageDetails.thumbnailImage.name);
    }
    return this.apiHttpService.postFormData(uri, formData);
  }

  updateMyProfile(request: UserProfile): Observable<null> {
    const saveProfile: SaveMyProfile = {
      firstName: request.firstName,
      lastName: request.lastName,
      preferredName: request.preferredName,
      contactNo: request.contactNumber,
      biographyContent: request.biography,
    };
    return this.apiHttpService.put(this.apiEndpointsProfileService.updateMyProfile(), saveProfile);
  }

  getProductFeature(productId: string, featureCode: string, stateId?: string): Observable<ProductFeature> {
    return this.apiHttpService.get<ProductFeature>(
      this.apiEndpointsProfileService.getProductFeature(productId, featureCode, stateId)
    );
  }

  static toUserProfile(response: UserProfileResponse): UserProfile {
    const userProfile = new UserProfile({
      firstName: response.firstName,
      lastName: response.lastName,
      contactNumber: response.contactNo,
      biography:
        response.biographies.find((c) => c.biographyType.toLowerCase() === 'defaultbiography'.toLowerCase())
          ?.biographyContent ?? '',
      company: '',
      email: response.emailAddress,
      preferredName: !response.preferredName ? `${response.firstName} ${response.lastName}` : response.preferredName,
      activatedInPlatform: response.activatedInPlatform,
    });
    userProfile.userImages = [];
    response.images.forEach((element) => {
      userProfile.userImages.push({
        id: element.id,
        imageType: element.imageType as ProfileImageType,
        originalUri: element.originalUri,
        thumbnailUri: element.thumbnailUri,
      });
    });
    return userProfile;
  }

  upsertCompanyLogo(tenantId: string, newImage: AddUserImage): Observable<UserImage> {
    const uri = this.apiEndpointsProfileService.upsertCompanyLogo();
    const formData = new FormData();
    formData.append('tenantId', tenantId);
    formData.append('clientImageType', newImage.imageType);
    if (newImage.originalImage) {
      formData.append('imageFile', newImage.originalImage, newImage.originalImage.name);
    }
    return this.apiHttpService.postFormData(uri, formData);
  }

  activateUserInPlatform() {
    const request: ActivateUserRequest = {
      productId: environment.productId,
      sendWelcomeEmail: currentUser().userType !== userTypes.vendor,
    };
    return this.apiHttpService.post(this.apiEndpointsProfileService.activateUser(), request);
  }

  canCreateMoreUsers(tenantId: string): Observable<boolean> {
    const url = this.apiEndpointsProfileService.canCreateMoreUsers(tenantId);
    return this.apiHttpService.get(url);
  }
}
