import {Injectable} from '@angular/core';
import {CmaId} from '@Shared/types/cma-id';
import {PropertyID} from '@Shared/types/property-id';
import {VendorID} from '@Shared/types/vendor-id';
import {QueryStringParameters} from '@Shared/classes/query-string-parameters/query-string-parameters';
import {ApiEndpointsService} from '../api-endpoints/api-endpoints.service';
import {CmaStatusType} from '@Shared/enums/cma-status-type';
import {DocumentID} from '@Shared/types/document-id';
import {CmaType} from '@Shared/enums/cma-type';
import {ImageId} from '@Shared/types/image-id';
import {FolioNumberRequest} from '@Shared/interfaces/api-requests/folio-number-request';
import {CheckFeatureQuotaRequest} from '@Shared/interfaces/api-requests/check-feature-quota-request';
import {OrderID} from '@Shared/types/order-id';
import {SearchUsersQuery} from '@Pages/user-management/interfaces/search-users-query';

@Injectable({
  providedIn: 'root',
})
export class ApiEndpointsRealEstateService extends ApiEndpointsService {
  private readonly baseUri = 're/api/v1';

  constructor() {
    super();
  }

  getOrderItemsByOrderId(orderId: OrderID): string {
    return this.createUrl(`${this.baseUri}/orders/${orderId}/get-order-items`);
  }

  addComparableProperty(): string {
    return this.createUrl(`${this.baseUri}/comparable-properties`);
  }

  updateComparablePropertySortOrder(cmaId: CmaId): string {
    return this.createUrl(`${this.baseUri}/comparative-market-analyses/${cmaId}/comparable-properties/sort-order`);
  }

  addSavedSearch(): string {
    return this.createUrl(`${this.baseUri}/search`);
  }

  addVendorToCma(cmaId: CmaId): string {
    return this.createUrl(`${this.baseUri}/comparative-market-analyses/${cmaId}/addVendor`);
  }

  createCma(): string {
    return this.createUrl(`${this.baseUri}/comparative-market-analyses`);
  }

  getCma(cmaId: CmaId): string {
    return this.createUrlWithQueryParameters(
      `${this.baseUri}/comparative-market-analyses/${cmaId}`,
      (qs: QueryStringParameters) => {
        qs.push('includeDeletedComparableProperties', true);
      }
    );
  }

  getCmaListByPropertyId(propertyId: PropertyID): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/comparative-market-analyses/by-target-property`, [
      propertyId,
    ]);
  }

  getCmaListByPropertyIdAndCmaType(propertyId: PropertyID, cmaType: CmaType): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/comparative-market-analyses/by-target-property`, [
      propertyId,
      CmaType[cmaType],
    ]);
  }

  getRoles(tenantId: string, productId: string): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/roles`, (qs: QueryStringParameters) => {
      qs.push('tenantId', tenantId);
      qs.push('productId', productId);
    });
  }

  searchUser(search: SearchUsersQuery): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/users`, (qs: QueryStringParameters) => {
      Object.entries(search).forEach(([key, value]) => qs.push(key, value));
    });
  }

  getCmaPdf(cmaId: CmaId): string {
    return this.createUrl(`${this.baseUri}/comparative-market-analyses/${cmaId}/pdf`);
  }

  finalisePdf(cmaId: CmaId): string {
    return this.createUrl(`${this.baseUri}/comparative-market-analyses/${cmaId}/finalise-pdf`);
  }

  getSavedSearches(): string {
    return this.createUrl(`${this.baseUri}/search`);
  }

  deleteComparableProperty(comparablePropertyId: string): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/comparable-properties`, [comparablePropertyId]);
  }

  removeVendorFromCma(cmaId: CmaId): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/comparative-market-analyses/${cmaId}/removeVendor`);
  }

  saveComparableProperty(): string {
    return this.createUrl(`${this.baseUri}/comparable-properties`);
  }

  saveDraftCMA(cmaId: CmaId): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/comparative-market-analyses`, [cmaId]);
  }

  saveSearch(searchId: string): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/search`, [searchId]);
  }

  saveVendor(vendorId: VendorID): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/vendors`, [vendorId]);
  }

  savePropertyImageToCma(cmaId: CmaId): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/comparative-market-analyses/${cmaId}/add-image`);
  }

  updateCmaPropertyImage(cmaId: CmaId, imageId: ImageId): string {
    return this.createUrlWithPathVariables(
      `${this.baseUri}/comparative-market-analyses/${cmaId}/update-image/${imageId}`
    );
  }

  getCmaTemplatePages(cmaId: CmaId): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/comparative-market-analyses/${cmaId}/get-template-pages`);
  }

  addCmaTemplatePage(cmaId: CmaId): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/comparative-market-analyses/${cmaId}/add-template-page`);
  }

  removeCmaTemplatePage(cmaId: CmaId): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/comparative-market-analyses/${cmaId}/remove-template-page`);
  }

  addEditedPropertyFieldToCma(cmaId: CmaId): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/comparative-market-analyses/${cmaId}/property-field`);
  }

  getVendorCmaSummaries(): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/vendors/cma-summaries`);
  }

  getSharedDocumentsByCmaId(cmaId: CmaId): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/comparative-market-analyses/${cmaId}/shared-documents`);
  }

  getDocuments(propertyId: PropertyID): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/properties/${propertyId}/documents`);
  }

  deleteDocument(propertyId: PropertyID): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/properties/${propertyId}/documents`);
  }

  uploadDocuments(propertyId: PropertyID): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/properties/${propertyId}/documents`);
  }

  shareDocument(propertyId: PropertyID, documentId: string): string {
    return this.createUrlWithPathVariables(
      `${this.baseUri}/properties/${propertyId}/documents/${encodeURIComponent(documentId)}/share`
    );
  }

  getDocumentUri(documentId: DocumentID): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/documents/${documentId}/uri`);
  }

  getWorkflow(status: CmaStatusType, accountId: string): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/agents/${accountId}/cma-dashboard?status=${status}`);
  }

  updateCMAStatus(cmaId: CmaId): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/comparative-market-analyses/${cmaId}/update-status`);
  }

  getCmaTemplates(cmaType: CmaType): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/cmaTemplates?cmaType=${cmaType}`);
  }

  getTitleOrders(propertyId: PropertyID): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/properties/${propertyId}/title-orders`);
  }

  placeOrder(): string {
    return this.createUrl(`${this.baseUri}/orders`);
  }

  getCmaContactDetails(cmaId: CmaId): string {
    return this.createUrl(`${this.baseUri}/comparative-market-analyses/${cmaId}/contact-details`);
  }

  deleteCustomImage(propertyId: string, imageId: string): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/properties/${propertyId}/custom-images`, [imageId]);
  }

  getAzureMapsToken(): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/Tokens/AzureMaps`);
  }

  updateDocument(documentId: DocumentID): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/documents`, [documentId]);
  }

  archiveCma(cmaId: CmaId): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/comparative-market-analyses/${cmaId}/archive`);
  }

  savePropertyImageOrder(propertyId: PropertyID): string {
    return this.createUrlWithPathVariables(`${this.baseUri}/properties/${propertyId}/property-image-order`);
  }

  getAllPropertyImages(propertyId: PropertyID, countryCode: string, includeListingsImages: boolean = true): string {
    return this.createUrlWithQueryParameters(
      `${this.baseUri}/properties/${propertyId}/all-property-images?CountryCode=${countryCode}&IncludeListingsImages=${includeListingsImages}`
    );
  }

  getRecentSearches(): string {
    return this.createUrl(`${this.baseUri}/recent-searches`);
  }

  saveRecentSearches(): string {
    return this.createUrl(`${this.baseUri}/recent-searches`);
  }

  getRecentlyViewed() {
    return this.createUrl(`${this.baseUri}/properties/recently-viewed`);
  }

  deleteRecentlyViewedProperty(id: string) {
    return this.createUrlWithPathVariables(`${this.baseUri}/properties/recently-viewed`, [id]);
  }

  createMapZone(): string {
    return this.createUrl(`${this.baseUri}/map-zones`);
  }

  updateMapZone(id: string): string {
    return this.createUrl(`${this.baseUri}/map-zones/${id}`);
  }

  getMapZoneById(id: string): string {
    return this.createUrl(`${this.baseUri}/map-zones/${id}`);
  }

  getMapZones(): string {
    return this.createUrl(`${this.baseUri}/map-zones`);
  }

  getSavedSearchesByMapZone(id: string): string {
    return this.createUrl(`${this.baseUri}/map-zones/${id}/saved-searches`);
  }

  deleteMapZone(id: string): string {
    return this.createUrl(`${this.baseUri}/map-zones/${id}`);
  }

  getFolioNumber(request: FolioNumberRequest): string {
    return this.createUrlWithQueryParameters(
      `${this.baseUri}/properties/${request.propertyId}/folio-number`,
      (qs: QueryStringParameters) => {
        qs.push('dipid', request.dipid);
        qs.push('lotNumber', request.lotNumber);
        qs.push('planNumber', request.planNumber);
      }
    );
  }

  getSuburbReport(propertyId: string, suburbId: string) {
    return this.createUrlWithPathVariables(
      `${this.baseUri}/properties/${propertyId}/reports/suburb-insights/${suburbId}`
    );
  }

  getPropertyInsightsReport(propertyId: PropertyID) {
    return this.createUrlWithPathVariables(`${this.baseUri}/properties/${propertyId}/reports/property-insights`);
  }

  getCertificateOfTitleSummaryReport(propertyId: PropertyID) {
    return this.createUrlWithPathVariables(
      `${this.baseUri}/properties/${propertyId}/reports/certificate-of-title-summary`
    );
  }

  sendTaxReceipt() {
    return this.createUrl(`${this.baseUri}/payments/tax-receipt`);
  }

  generateDigitalCmaUrl(cmaId: CmaId): string {
    return this.createUrl(`${this.baseUri}/comparative-market-analyses/${cmaId}/generate-web-cma-url`);
  }

  getWebCmaPdfStatus(documentId: DocumentID): string {
    return this.createUrl(`${this.baseUri}/documents/${documentId}/check-status`);
  }

  exportSearchResult(): string {
    return this.createUrl(`${this.baseUri}/search/export-search-result`);
  }

  getMyClientProfile(): string {
    return this.createUrl(`${this.baseUri}/client-profiles/me`);
  }

  saveClientProfile(): string {
    return this.createUrl(`${this.baseUri}/client-profiles`);
  }

  checkFeatureQuota(request: CheckFeatureQuotaRequest): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/orders/check-quota?featureCode=${request.featureCode}`);
  }

  openTelemetryTraces(): string {
    return this.createUrl(`${this.baseUri}/telemetry/traces`);
  }

  openTelemetryMetrics(): string {
    return this.createUrl(`${this.baseUri}/telemetry/metrics`);
  }

  getOnePagePropertyReport(propertyId: PropertyID): string {
    return this.createUrl(`${this.baseUri}/properties/${propertyId}/reports/property-one-page`);
  }

  uploadCmaPageInsert(): string {
    return this.createUrl(`${this.baseUri}/comparative-market-analyses/cma-page-insert`);
  }

  deleteCmaPageInsert(documentId: string): string {
    return this.createUrlWithQueryParameters(
      `${this.baseUri}/comparative-market-analyses/cma-page-insert`,
      (qs: QueryStringParameters) => {
        qs.push('documentId', documentId);
      }
    );
  }
}
